export const navItems = [
    {
        id : "#home",
        name : "Home",
        link : "/",
    },
    {
        id : "#about",
        name : "About",
        link : "/about",
    },
    // {
    //     id : "#department",
    //     name : "Department",
    //     link : "/department",
    // },
    {
        id : "#doctors",
        name : "Doctors",
        link : "/doctors",
    },
    {
        id : "#gallery",
        name : "Gallery",
        link : "/gallery",
    },
    {
        id : "#reviews",
        name : "Reviews",
        link : "/reviews",
    },
    {
        id : "#contact",
        name : "Contact",
        link : "/contact",
    },
]