import React from 'react';
import './Map.scss';

function Map() {
    return (
        <div className='map-component'>
            <div className="gmap_canvas">
                <iframe  id="gmap_canvas" src="https://maps.google.com/maps?q=SOURAVS%27+PATHOLOGY%2C+Natna%2C+Karimpur%2C+West+Bengal+741122&t=&z=14&ie=UTF8&iwloc=&output=embed"></iframe>
            </div>
        </div>
    )
}

export default Map;


