import React, { useEffect, useState } from "react";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { DoctorsPage } from "../../Datas/PagesData";

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4,
        slidesToSlide: 4 // optional, default to 1.
    },
    tablet: {
        breakpoint: { max: 1080, min: 768 },
        items: 3,
        slidesToSlide: 3 // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 780, min: 464 },
        items: 2,
        slidesToSlide: 1 // optional, default to 1.
    }
};

function Slider() {
    return (
        <div className="parent">
            <Carousel
                responsive={responsive}
                autoPlay={true}
                swipeable={true}
                draggable={true}
                showDots={true}
                infinite={true}
                partialVisible={false}
                dotListClass="custom-dot-list-style"
            >
                {DoctorsPage.doctors.map((item, index) => (
                    <div className="slider" key={index}>
                        <div className="image-container">
                            <img src={item.img} alt={item} className='image' />
                        </div>
                        <h4 className="dr-title">{item.name}</h4>
                        <p className="dr-category">{item.designation}</p>
                    </div>
                ))}
            </Carousel>
        </div>
    );
};
export default Slider;
