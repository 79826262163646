import React from 'react';
import './Announcement.scss';
import { AnnounsmentData } from '../../Datas/Announsment';

function Announcement() {
    return (
        <div className="announcement-components">
            <marquee className="marquee">{AnnounsmentData}</marquee>
        </div>
    )
}
export default Announcement;