import React from 'react';
import './Home.scss';
import BackgroundImg from '../../Images/Background/home.png';
import { Button } from 'react-bootstrap';
import {AiFillFacebook, AiFillInstagram, AiOutlineWhatsApp} from 'react-icons/ai';
import { HomePage } from '../../Datas/PagesData';
import { SocialLinks } from '../../Datas/SocialLinks';

function Home() {
    return (
        <div className="home-component" id="home">
            <img src={BackgroundImg} alt="BackgroundImg" className='BackgroundImg' />
            <div className="home-container">
                <div className="heading-box">
                    <h1 className="title">{HomePage.title1} <br />{HomePage.title2}<span></span>.</h1>
                    <p className="para">
                        {HomePage.para1} <br />  {HomePage.para2}
                    </p>
                    <Button className='appointment-btn'>Appointment</Button>
                    <div className="social-link-box">
                        <a href={SocialLinks.facebook} className='link-item'>
                            <AiFillFacebook className='link-icon'/>
                        </a>
                        <a href={SocialLinks.instagram} className='link-item'>
                            <AiFillInstagram className='link-icon'/>
                        </a>
                        <a href={SocialLinks.whatsapp} className='link-item'>
                            <AiOutlineWhatsApp className='link-icon'/>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home