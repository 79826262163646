import React from 'react';
import './Service.scss';
import { ServicePage } from '../../Datas/PagesData';

function Service() {
  return (
    <div className="service-components">
      <div className="service-container">
        {
          ServicePage.map((item, index) => (
            <div className="service-box" key={index}>
              <div className={`icon-box ${item.iconCls}`}>
                {item.icon}
              </div>
              <h4 className="service-title">{item.title}</h4>
              <p className="service-para">{item.tags}</p>
            </div>
          ))
        }
      </div>
    </div>
  )
}

export default Service