import React from 'react';
import Slider from './Slider';
import './Review.scss';
import { ReviewPage } from '../../Datas/PagesData';

function Review() {
    return (
        <div className='review-components' id='reviews'>
            <div className='review-container'>
                <div className="review-heading-container">
                    <h1 className="heading-title">{ReviewPage.headline}</h1>
                    <div className="style-box">
                        <div className="border-style"></div>
                        <div className="round-style"></div>
                        <div className="border-style"></div>
                    </div>
                    <p className="heading-para">{ReviewPage.headlinePara1} <br /> {ReviewPage.headlinePara2}</p>
                </div>

                <div className="review-buttom-container">
                    <Slider />
                </div>

            </div>
        </div>
    )
}

export default Review