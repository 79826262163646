import React from 'react';
import './Doctors.scss';
import Slider from './Slider';
import { DoctorsPage } from '../../Datas/PagesData';

function Doctors(){
    return (
        <div className='doctors-components' id='doctors'>
            <div className='doctors-container'>
                <div className="doctors-heading-container">
                    <h1 className="heading-title">{DoctorsPage.headline}</h1>
                    <div className="style-box">
                        <div className="border-style"></div>
                        <div className="round-style"></div>
                        <div className="border-style"></div>
                    </div>
                    <p className="heading-para">{DoctorsPage.headlinePara1} <br /> {DoctorsPage.headlinePara2}</p>
                </div>

                <div className="doctors-buttom-container">
                    <Slider/>
                </div>

            </div>
        </div>
    )
}
export default Doctors;