import React from 'react'
import './Contact.scss';
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { Button } from 'react-bootstrap';
import { ContactPage } from '../../Datas/PagesData';

const schema = yup.object({
    name: yup.string().required(),
    number: yup.string().required(),
    email: yup.string().required(),
    subject: yup.string().required(),
    message: yup.string(),
}).required()

function Contact() {
    const { register, handleSubmit, formState: { errors }, } = useForm({ resolver: yupResolver(schema) })
    const onSubmit = (data) => console.log(data)
    return (
        <div className="contact-components" id="contact">
            <div className="contact-container">
                <div className="contact-heading-container">
                    <h1 className="heading-title">{ContactPage.headline}</h1>
                    <div className="style-box">
                        <div className="border-style"></div>
                        <div className="round-style"></div>
                        <div className="border-style"></div>
                    </div>
                    <p className="heading-para">{ContactPage.headlinePara1} <br /> {ContactPage.headlinePara2}</p>
                </div>

                <div className="contact-form-container">
                    <form onSubmit={handleSubmit(onSubmit)} className='form-container'>
                        <div className="group-item">
                            <div className="item-box">
                                <p className="title">Full Name</p>
                                <input {...register("name")} name='name' type='text' className='input-box' placeholder='Enter Your Name' required />
                            </div>

                            <div className="item-box">
                                <p className="title">Email</p>
                                <input {...register("email")} name='email' type='email' className='input-box' placeholder='Enter Your Email' required/>
                            </div>
                        </div>

                        <div className="group-item">
                            <div className="item-box">
                                <p className="title">Phone Number</p>
                                <input {...register("number")} name='number' type='number' className='input-box' placeholder='Enter Your Number' required />
                            </div>

                            <div className="item-box">
                                <p className="title">Subject</p>
                                <input {...register("subject")} name='subject' type='text' className='input-box' placeholder='Enter Your Subject' required />
                            </div>
                        </div>

                        <div className="group-item">
                            <div className="item-box-textarea">
                                <p className="title">Message</p>
                                <textarea {...register("message")} name='message' className="text-area" placeholder='Write something here...' required></textarea>
                            </div>
                        </div>

                        <Button className='appointment-btn' type='submit'>Send Message</Button>
                    </form>
                </div>

            </div>
        </div>
    )
}

export default Contact