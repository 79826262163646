import React, { useEffect, useState } from 'react';
import './Gallery.scss';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { GalleryPage } from '../../Datas/PagesData';

function Gallery() {
    const [category, setCategory] = useState("All");
    const [imgData, setImgData] = useState(GalleryPage.AllImg);
    
    useEffect(() => {
        if (category === "All") {
            setImgData(GalleryPage.AllImg)
        } else if (category === "Cardiology") {
            setImgData(GalleryPage.CardiologyImg)
        } else if (category === "Neurology") {
            setImgData(GalleryPage.NeurologyImg)
        } else if (category === "Urology") {
            setImgData(GalleryPage.UrologyImg)
        } else if (category === "Traumatology") {
            setImgData(GalleryPage.TraumatologyImg)
        }
    }, [category]);

    return (
        <div className='gallery-components' id='gallery'>
            <div className='gallery-container'>
                <div className="gallery-heading-container">
                    <h1 className="heading-title">{GalleryPage.headline}</h1>
                    <div className="style-box">
                        <div className="border-style"></div>
                        <div className="round-style"></div>
                        <div className="border-style"></div>
                    </div>
                    <p className="heading-para">{GalleryPage.headlinePara1} <br /> {GalleryPage.headlinePara2}</p>
                </div>

                <div className="category-containeer">
                    <div className="category-box">
                        <p className={`item ${category === "All" ? "item-focus" : null}`} onClick={() => setCategory('All')}>All</p>
                        <p className={`item ${category === "Cardiology" ? "item-focus" : null}`} onClick={() => setCategory('Cardiology')}>Cardiology</p>
                        <p className={`item ${category === "Neurology" ? "item-focus" : null}`} onClick={() => setCategory('Neurology')}>Neurology</p>
                        <p className={`item ${category === "Urology" ? "item-focus" : null}`} onClick={() => setCategory('Urology')}>Urology</p>
                        <p className={`item ${category === "Traumatology" ? "item-focus" : null}`} onClick={() => setCategory('Traumatology')}>Traumatology</p>
                    </div>
                </div>

                <div className="gallery-buttom-container">
                    <ImageList sx={{ width: '100%' }} variant="masonry" cols={3} gap={8}>
                        {imgData.map((item, index) => (
                            <ImageListItem key={index}>
                                <img
                                    srcSet={`${item.img}?w=161&fit=crop&auto=format&dpr=2 2x`}
                                    src={`${item.img}?w=161&fit=crop&auto=format`}
                                    alt={item.title}
                                    loading="lazy"
                                />
                            </ImageListItem>
                        ))}
                    </ImageList>
                </div>

            </div>
        </div>
    )
}

const itemData = [
    {
        img: 'https://img.freepik.com/premium-photo/laboratory-microscope-is-placed-table_714091-969.jpg?size=626&ext=jpg&ga=GA1.1.1413502914.1696377600&semt=sph',
        title: 'img1',
    },
    {
        img: 'https://st4.depositphotos.com/2790227/31579/i/450/depositphotos_315797172-stock-photo-modern-microscope-station-in-research.jpg',
        title: 'img4',
    },
    {
        img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQixgOgs8H8oz2Fr01rEw69svaCDKOuv2uqoQ&usqp=CAU',
        title: 'img2',
    },
    {
        img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRXNi_5HVOfkWbRKtHP1ctmVYOkOttVxzsGXQ&usqp=CAU',
        title: 'img3',
    },
    {
        img: 'https://img.freepik.com/premium-photo/forensic-science_798657-12681.jpg?size=626&ext=jpg&ga=GA1.1.1880011253.1699228800&semt=ais',
        title: 'img5',
    },
    {
        img: 'https://www.citymedicalchambers.org/wp-content/uploads/revslider/home6/bnr-img1.png',
        title: 'img6',
    },
];



export default Gallery;