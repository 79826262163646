import React from 'react';
import './Appointment.scss';
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { Button } from 'react-bootstrap';
import { AppointmentPage } from '../../Datas/PagesData';

const schema = yup.object({
    name: yup.string().required(),
    number: yup.string().required(),
    email: yup.string(),
    date: yup.string().required(),
    department: yup.string().required(),
    doctor: yup.string().required(),
    message: yup.string(),
}).required()


function Appointment() {
    const { register, handleSubmit, formState: { errors }, } = useForm({ resolver: yupResolver(schema) })
    const onSubmit = (data) => console.log(data)
    return (
        <div className="appointment-components" id='appointment'>
            <div className="appointment-container">
                <div className="appointment-heading-container">
                    <h1 className="heading-title">{AppointmentPage.headline}</h1>
                    <div className="style-box">
                        <div className="border-style"></div>
                        <div className="round-style"></div>
                        <div className="border-style"></div>
                    </div>
                    <p className="heading-para">{AppointmentPage.headlinePara1} <br /> {AppointmentPage.headlinePara2}</p>
                </div>

                <div className="appointment-form-container">
                    <form onSubmit={handleSubmit(onSubmit)} className='form-container'>
                        <div className="group-item">
                            <div className="item-box">
                                <p className="title">Full Name</p>
                                <input {...register("name")} name='name' type='text' className='input-box' placeholder='Enter Your Name' required/>
                            </div>

                            <div className="item-box">
                                <p className="title">Email</p>
                                <input {...register("email")} name='email' type='email' className='input-box' placeholder='Enter Your Email' />
                            </div>
                        </div>

                        <div className="group-item">
                            <div className="item-box">
                                <p className="title">Phone Number</p>
                                <input {...register("number")} name='number' type='number' className='input-box' placeholder='Enter Your Number' required/>
                            </div>

                            <div className="item-box">
                                <p className="title">Booking Date</p>
                                <input {...register("date")} name='date' type='date' className='input-box' required/>
                            </div>
                        </div>

                        <div className="group-item">
                            <div className="item-box">
                                <p className="title">Department</p>
                                <select {...register("department")} name='department' className='select-box' required>
                                    <option value=''>Select Department</option>
                                    <option value='Skin'>Skin</option>
                                </select>
                            </div>

                            <div className="item-box">
                                <p className="title">Doctor</p>
                                <select {...register("doctor")} name='doctor' className='select-box' required>
                                    <option value=''>Select Department</option>
                                    <option value='Rahul'>Rahul</option>
                                </select>
                            </div>
                        </div>

                        <div className="group-item">
                            <div className="item-box-textarea">
                                <p className="title">Message</p>
                                <textarea {...register("message")} name='message' className="text-area" placeholder='Write something here...'></textarea>
                            </div>
                        </div>

                        <Button className='appointment-btn' type='submit'>Appointment</Button>
                    </form>
                </div>

            </div>
        </div>
    )
}

export default Appointment