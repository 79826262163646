import React, { useEffect, useState } from 'react';
import './AppBar.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import logo from '../../Images/Logo/360_F_267735982_bVaJnLumRzMBp7uRwK41RkewLKMhosbG-removebg-preview.png';
import { navItems } from '../../Datas/Navbar';
function AppBar() {
    const [scrollVal, setScrollVal] = useState(0);
    const [clsName, setClsName] = useState();
    const expand = "xl";

    window.addEventListener("scroll", (event) => {
        setScrollVal(window.scrollY);
    });

    useEffect(() => {
        const homeDiv = document.getElementById("home")
        if(scrollVal <= 50){
            setClsName("nav-not-fixed");
            homeDiv.style.marginTop='-16px';
        }else{
            homeDiv.style.marginTop='100px';
            setClsName("nav-fixed");
        }
    },[scrollVal]);

    return (
        <Navbar key={expand} expand={expand} className={`"bg-body-tertiary mb-3 navbar ${clsName} `}>
            <Container fluid className="box-container">
                <Navbar.Brand href="/" className='brand'>
                    <img src={logo} alt="logo" className='logo' />
                    Sourav Pathology
                </Navbar.Brand>
                <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
                <Navbar.Offcanvas id={`offcanvasNavbar-expand-${expand}`} aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`} placement="end">
                    <Offcanvas.Header closeButton className='mobile-nav'>
                        <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`} className='nav-head-box'>
                            <img src={logo} alt="logo" className='logo' />
                            Sourav Pathology
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Nav className="justify-content-end flex-grow-1 pe-3">
                            {
                                navItems.map((item) => (
                                    <Nav.Link href={item.id} className='href-item' key={item.id} id={item.id}>{item.name}</Nav.Link>
                                ))
                            }
                        </Nav>
                        <a href='#appointment' className='appoint-btn'>Appointment</a>
                    </Offcanvas.Body>
                </Navbar.Offcanvas>
            </Container>
        </Navbar>
    )
}

export default AppBar