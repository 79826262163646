import React, { useEffect, useState } from "react";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { FaQuoteRight } from "react-icons/fa6";
import { ReviewPage } from "../../Datas/PagesData";

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        slidesToSlide: 4 // optional, default to 1.
    },
    tablet: {
        breakpoint: { max: 1080, min: 768 },
        items: 2,
        slidesToSlide: 3 // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 780, min: 464 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    }
};

function Slider() {
    const [Items, setItems] = useState([
        "https://i.pinimg.com/1200x/1b/bc/d4/1bbcd4bc95c3c3221b11b06995b146b7.jpg",
        "https://i.pinimg.com/736x/1a/ac/b7/1aacb718f4086c68ed9c47cd26a2b032.jpg",
        "https://i.pinimg.com/736x/b9/97/a5/b997a530822d0f2c03259070d4590d45.jpg",
        "https://d3edssu4vf7j1p.cloudfront.net/images/detailed/49/young-female-doctor-white-dress-19541983.jpg?t=1676300610",
        "https://play-lh.googleusercontent.com/oihKMprQdkhVcwkvWIv6HoFWHxKFbae2jFdHiOjwQmeku9IVebxAj6EQJYBnvdxWLPA",
        "https://media.sciencephoto.com/image/m5410287/800wm/M5410287.jpg",
        "https://thumbs.dreamstime.com/z/beautiful-young-female-doctor-9182291.jpg",
    ]);

    return (
        <div className="parent">
            <Carousel
                responsive={responsive}
                autoPlay={true}
                swipeable={true}
                draggable={true}
                showDots={true}
                infinite={true}
                partialVisible={false}
                dotListClass="custom-dot-list-style"
            >
                {ReviewPage.Review?.map((item, index) => (
                    <div className="slider" key={index}>
                        <div className="image-container">
                            <img src={item.img} alt={item.name} className='image' />
                        </div>

                        <div className="head-box">
                            <p className="dr-title">{item.name}</p>
                            <p className="dr-category">{item.designation}</p>
                        </div>

                        <p className="paragrapg">{item.describtion}</p>
                        <FaQuoteRight className="icon"/>
                    </div>
                ))}
            </Carousel>
        </div>
    );
};
export default Slider;
