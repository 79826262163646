import React from 'react';
import './Footer.scss';
import logo from '../../Images/Logo/360_F_267735982_bVaJnLumRzMBp7uRwK41RkewLKMhosbG-removebg-preview.png';
import { AiFillFacebook, AiFillInstagram, AiOutlineWhatsApp } from 'react-icons/ai';
import { BiChevronRight } from 'react-icons/bi';
import { SocialLinks } from '../../Datas/SocialLinks';
import { FooterPage } from '../../Datas/PagesData';

function Footer() {
    const date = new Date();
    return (
        <div className="footer-components">
            <div className="footer-top-components">
                <div className="container-box">
                    <div className="brand-item">
                        <img src={logo} alt="logo" className='logo' />
                        <h1 className="brand-title">Sourav Pathology</h1>
                    </div>
                    <p className="paragraph">{FooterPage.description}</p>

                    <div className="social-link-box">
                        <a href={SocialLinks.facebook} className='link-item' target='_blank'>
                            <AiFillFacebook className='link-icon' />
                        </a>
                        <a href={SocialLinks.instagram} className='link-item' target='_blank'>
                            <AiFillInstagram className='link-icon' />
                        </a>
                        <a href={SocialLinks.whatsapp} className='link-item' target='_blank'>
                            <AiOutlineWhatsApp className='link-icon' />
                        </a>
                    </div>
                </div>
                <div className="container-link-box container-link-box-sec">
                    <h4 className="title">Useful Links</h4>
                    <div className="divider-border"></div>
                    <div className="link-items-box">
                        <a href="#department" className='href-item'><BiChevronRight className='item-icon' />Department</a>
                        <a href="#doctors" className='href-item'><BiChevronRight className='item-icon' />Doctors</a>
                        <a href="#appointment" className='href-item'><BiChevronRight className='item-icon' />Appointment</a>
                        <a href="#contact" className='href-item'><BiChevronRight className='item-icon' />Contact</a>
                    </div>
                </div>
                <div className="container-link-box container-link-box-sec">
                    <h4 className="title">Useful Links</h4>
                    <div className="divider-border"></div>
                    <div className="link-items-box">
                        <a href="#home" className='href-item'><BiChevronRight className='item-icon' />Home</a>
                        <a href="#about" className='href-item'><BiChevronRight className='item-icon' />About</a>
                        <a href="#reviews" className='href-item'><BiChevronRight className='item-icon' />Reviews</a>
                        <a href="#gallery" className='href-item'><BiChevronRight className='item-icon' />Gallery</a>
                    </div>
                </div>
                <div className="container-link-box">
                    <h4 className="title">Contacts</h4>
                    <div className="divider-border"></div>
                    <div className="contact-items-box">
                        <div className="address-box">
                            <p className="title-address">Address:</p>
                            <p className="para-address">{FooterPage.Address}</p>
                        </div>
                        <div className="address-box">
                            <p className="title-address">Email:</p>
                            <p className="para-address">{FooterPage.Email}</p>
                        </div>
                        <div className="address-box">
                            <p className="title-address">Phone:</p>
                            <p className="para-address">{FooterPage.Phone}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-buttom-components">
                <div className="item-box-footer">
                    <p className="para">@{date.getFullYear()} Sourav Pathology All Right Reserved</p>
                    <p className="para">Created by <a href='https://techaquarius.com/' target='_blank' className="href">techaquarius.com</a></p>
                </div>
            </div>
        </div>
    )
}

export default Footer