import { FaUserDoctor } from "react-icons/fa6";
import { LuSiren, LuPhoneCall } from "react-icons/lu";
import drImg from "../Images/Elements/drImg.jpg";

export const HomePage = {
  title1: "Take best quality",
  title2: "treatment for ",
  para1:
    "The art of medicine consists in amusing the patient while nature cures the disease.",
  para2: "Treatment without prevention is simply unsustainable.",
};

export const ServicePage = [
  {
    title: "Qualified Doctors",
    tags: "Lorem ipsum dolor sit amet consectet adipis sed do eiusmod tempor inc ididunt utid labore.",
    icon: <FaUserDoctor className="service-icon" />,
    iconCls: "st-box",
  },
  {
    title: "24 Hours Service",
    tags: "Lorem ipsum dolor sit amet consectet adipis sed do eiusmod tempor inc ididunt utid labore.",
    icon: <LuPhoneCall className="service-icon" />,
    iconCls: "nd-box",
  },
  {
    title: "Need Emergency",
    tags: "Lorem ipsum dolor sit amet consectet adipis sed do eiusmod tempor inc ididunt utid labore.",
    icon: <LuSiren className="service-icon" />,
    iconCls: "rd-box",
  },
];

export const AboutPage = {
  headline: "Who We Are",
  headlinePara1:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  headlinePara2: "Lorem Ipsum the industrys standard dummy text.",
  contantTitle:
    "A hospital is a health care institution providing patient treatment with specialized medical",
  contantPara1:
    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur modi, sunt ut quam illo dolore deleniti dolor, inventore, nam voluptate doloremque. Pariatur necessitatibus autem adipisci aperiam fuga similique expedita consequatur.",
  contantPara2:
    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur, sequi ipsam. Quasi perspiciatis iure cumque, illo praesentium fuga quia unde magni nulla quibusdam! Modi eaque quas ratione quam, incidunt iusto. Quasi perspiciatis iure cumque, illo praesentium fuga quia unde magni nulla quibusdam! Modi eaque quas ratione quam, incidunt iusto.",
  contactNumber: "9090909090",
  owner: {
    name: "David Ambrose",
    designation: "Founder & Director",
    image: drImg,
  },

  Timetable: [
    {
      day: "Monday",
      time: "8:00am–7:00pm",
    },
    {
      day: "Tuesday",
      time: "8:00am–7:00pm",
    },
    {
      day: "Wednesday",
      time: "8:00am–7:00pm",
    },
    {
      day: "Thursday",
      time: "8:00am–7:00pm",
    },
    {
      day: "Friday",
      time: "8:00am–7:00pm",
    },
    {
      day: "Saturday",
      time: "8:00am–7:00pm",
    },
    {
      day: "Sunday",
      time: "8:00am–7:00pm",
    },
  ],
};

export const DoctorsPage = {
  headline: "Meet Our Specialists",
  headlinePara1:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  headlinePara2: "Lorem Ipsum the industrys standard dummy text.",

  doctors: [
    {
      name: "Dr.Vera Hossen",
      designation: "Cardiology",
      img: "https://i.pinimg.com/1200x/1b/bc/d4/1bbcd4bc95c3c3221b11b06995b146b7.jpg",
    },
    {
      name: "Dr.Vera Hossen",
      designation: "Cardiology",
      img: "https://i.pinimg.com/736x/1a/ac/b7/1aacb718f4086c68ed9c47cd26a2b032.jpg",
    },
    {
        name: "Dr.Vera Hossen",
        designation: "Cardiology",
      img: "https://i.pinimg.com/736x/b9/97/a5/b997a530822d0f2c03259070d4590d45.jpg",
    },
    {
      name: "Dr.Vera Hossen",
      designation: "Cardiology",
      img: "https://d3edssu4vf7j1p.cloudfront.net/images/detailed/49/young-female-doctor-white-dress-19541983.jpg?t=1676300610",
    },
    {
      name: "Dr.Vera Hossen",
      designation: "Cardiology",
      img: "https://play-lh.googleusercontent.com/oihKMprQdkhVcwkvWIv6HoFWHxKFbae2jFdHiOjwQmeku9IVebxAj6EQJYBnvdxWLPA",
    },
    {
      name: "Dr.Vera Hossen",
      designation: "Cardiology",
      img: "https://media.sciencephoto.com/image/m5410287/800wm/M5410287.jpg",
    },
    {
      name: "Dr.Vera Hossen",
      designation: "Cardiology",
      img: "https://thumbs.dreamstime.com/z/beautiful-young-female-doctor-9182291.jpg",
    },
  ],
};

export const AppointmentPage = {
    headline: "Make An Appointment",
    headlinePara1: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    headlinePara2: "Lorem Ipsum the industrys standard dummy text.",
};

export const GalleryPage = {
    headline: "View Our Gallery",
    headlinePara1: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    headlinePara2: "Lorem Ipsum the industrys standard dummy text.",

    AllImg : [
        {
            img: 'https://img.freepik.com/premium-photo/laboratory-microscope-is-placed-table_714091-969.jpg?size=626&ext=jpg&ga=GA1.1.1413502914.1696377600&semt=sph',
            title: 'img1',
        },
        {
            img: 'https://st4.depositphotos.com/2790227/31579/i/450/depositphotos_315797172-stock-photo-modern-microscope-station-in-research.jpg',
            title: 'img4',
        },
        {
            img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQixgOgs8H8oz2Fr01rEw69svaCDKOuv2uqoQ&usqp=CAU',
            title: 'img2',
        },
        {
            img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRXNi_5HVOfkWbRKtHP1ctmVYOkOttVxzsGXQ&usqp=CAU',
            title: 'img3',
        },
        {
            img: 'https://img.freepik.com/premium-photo/forensic-science_798657-12681.jpg?size=626&ext=jpg&ga=GA1.1.1880011253.1699228800&semt=ais',
            title: 'img5',
        },
        {
            img: 'https://www.citymedicalchambers.org/wp-content/uploads/revslider/home6/bnr-img1.png',
            title: 'img6',
        },
    ],

    CardiologyImg : [
        {
            img: 'https://img.freepik.com/premium-photo/laboratory-microscope-is-placed-table_714091-969.jpg?size=626&ext=jpg&ga=GA1.1.1413502914.1696377600&semt=sph',
            title: 'img1',
        },
        {
            img: 'https://st4.depositphotos.com/2790227/31579/i/450/depositphotos_315797172-stock-photo-modern-microscope-station-in-research.jpg',
            title: 'img4',
        },
        {
            img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQixgOgs8H8oz2Fr01rEw69svaCDKOuv2uqoQ&usqp=CAU',
            title: 'img2',
        },
    ],

    NeurologyImg : [
        {
            img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRXNi_5HVOfkWbRKtHP1ctmVYOkOttVxzsGXQ&usqp=CAU',
            title: 'img3',
        },
        {
            img: 'https://img.freepik.com/premium-photo/forensic-science_798657-12681.jpg?size=626&ext=jpg&ga=GA1.1.1880011253.1699228800&semt=ais',
            title: 'img5',
        },
        {
            img: 'https://www.citymedicalchambers.org/wp-content/uploads/revslider/home6/bnr-img1.png',
            title: 'img6',
        },
    ],

    UrologyImg : [
        {
            img: 'https://st4.depositphotos.com/2790227/31579/i/450/depositphotos_315797172-stock-photo-modern-microscope-station-in-research.jpg',
            title: 'img4',
        },
        {
            img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQixgOgs8H8oz2Fr01rEw69svaCDKOuv2uqoQ&usqp=CAU',
            title: 'img2',
        },
        {
            img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRXNi_5HVOfkWbRKtHP1ctmVYOkOttVxzsGXQ&usqp=CAU',
            title: 'img3',
        }
    ],

    TraumatologyImg : [
        {
            img: 'https://st4.depositphotos.com/2790227/31579/i/450/depositphotos_315797172-stock-photo-modern-microscope-station-in-research.jpg',
            title: 'img4',
        },
        {
            img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRXNi_5HVOfkWbRKtHP1ctmVYOkOttVxzsGXQ&usqp=CAU',
            title: 'img3',
        },
        {
            img: 'https://www.citymedicalchambers.org/wp-content/uploads/revslider/home6/bnr-img1.png',
            title: 'img6',
        },
    ],
};

export const ReviewPage = {
    headline: "What Peoble Say?",
    headlinePara1: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    headlinePara2: "Lorem Ipsum the industrys standard dummy text.",
  
    Review : [
      {
        name: "Dr.Vera Hossen",
        designation: "Cardiology",
        describtion : "Lorem ipsum dolor consectetur is a adipisicing elit. Quis omnis similique dolore obcaecati earum recusandae dolore obcaecati.",
        img: "https://i.pinimg.com/1200x/1b/bc/d4/1bbcd4bc95c3c3221b11b06995b146b7.jpg",
      },
      {
        name: "Dr.Vera Hossen",
        designation: "Cardiology",
        describtion : "Lorem ipsum dolor consectetur is a adipisicing elit. Quis omnis similique dolore obcaecati earum recusandae dolore obcaecati.",
        img: "https://i.pinimg.com/736x/1a/ac/b7/1aacb718f4086c68ed9c47cd26a2b032.jpg",
      },
      {
        name: "Dr.Vera Hossen",
        designation: "Cardiology",
        describtion : "Lorem ipsum dolor consectetur is a adipisicing elit. Quis omnis similique dolore obcaecati earum recusandae dolore obcaecati.",
        img: "https://i.pinimg.com/736x/b9/97/a5/b997a530822d0f2c03259070d4590d45.jpg",
      },
      {
        name: "Dr.Vera Hossen",
        designation: "Cardiology",
        describtion : "Lorem ipsum dolor consectetur is a adipisicing elit. Quis omnis similique dolore obcaecati earum recusandae dolore obcaecati.",
        img: "https://d3edssu4vf7j1p.cloudfront.net/images/detailed/49/young-female-doctor-white-dress-19541983.jpg?t=1676300610",
      },
      {
        name: "Dr.Vera Hossen",
        designation: "Cardiology",
        describtion : "Lorem ipsum dolor consectetur is a adipisicing elit. Quis omnis similique dolore obcaecati earum recusandae dolore obcaecati.",
        img: "https://play-lh.googleusercontent.com/oihKMprQdkhVcwkvWIv6HoFWHxKFbae2jFdHiOjwQmeku9IVebxAj6EQJYBnvdxWLPA",
      },
      {
        name: "Dr.Vera Hossen",
        designation: "Cardiology",
        describtion : "Lorem ipsum dolor consectetur is a adipisicing elit. Quis omnis similique dolore obcaecati earum recusandae dolore obcaecati.",
        img: "https://media.sciencephoto.com/image/m5410287/800wm/M5410287.jpg",
      },
      {
        name: "Dr.Vera Hossen",
        designation: "Cardiology",
        describtion : "Lorem ipsum dolor consectetur is a adipisicing elit. Quis omnis similique dolore obcaecati earum recusandae dolore obcaecati.",
        img: "https://thumbs.dreamstime.com/z/beautiful-young-female-doctor-9182291.jpg",
      },
    ],
};

export const ContactPage = {
    headline: "Stay Connect With Us",
    headlinePara1: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    headlinePara2: "Lorem Ipsum the industrys standard dummy text.",
};

export const FooterPage = {
    description : "Lorem ipsum dolor sit consectet adipisicing sed do eiusmod temp incididunt ut labore. Lorem Ipsum is simply dummy.",
    Address : "Natna, Karimpur, West Bengal, 741122",
    Email : "info@souravpathology.com",
    Phone : "+91 8080808080"
}