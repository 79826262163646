import React, { createContext } from "react";
import Announcement from "./Components/Announcement/Announcement";
import AppBar from "./Components/AppBar/AppBar";
import HomePage from "./Pages/HomePage";
import ServicePage from "./Pages/ServicePage";
import AboutPage from "./Pages/AboutPage";
import DepartmentPage from "./Pages/DepartmentPage";
import DoctorsPages from "./Pages/DoctorsPages";
import AppointmentPage from "./Pages/AppointmentPage";
import GalleryPage from "./Pages/GalleryPage";
import ReviewPage from './PagasComponents/Review/Review';
import ContactPage from "./Pages/ContactPage";
import MapPage from "./Pages/MapPage";
import Footer from "./Components/Footer/Footer";

export const GlobalStorage = createContext();
function App() {
  return (
    <div className="App">
      <Announcement />
      <AppBar />
      <HomePage />
      <ServicePage />
      <AboutPage />
      {/* <DepartmentPage/> */}
      <DoctorsPages/>
      <AppointmentPage />
      <GalleryPage />
      <ReviewPage/>
      <ContactPage/>
      <MapPage/>
      <Footer /> 
    </div>
  );
}

export default App;
