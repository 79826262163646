import React from 'react';
import "./About.scss";
import drImg from '../../Images/Elements/drImg.jpg';
import { FiPhoneCall } from 'react-icons/fi';
import { AboutPage } from '../../Datas/PagesData';

function About() {
    return (
        <div className="about-components" id='about'>
            <div className="about-container">
                <div className="about-heading-container">
                    <h1 className="heading-title">{AboutPage.headline}</h1>
                    <div className="style-box">
                        <div className="border-style"></div>
                        <div className="round-style"></div>
                        <div className="border-style"></div>
                    </div>
                    <p className="heading-para">{AboutPage.headlinePara1} <br /> {AboutPage.headlinePara2} </p>
                </div>

                <div className="about-details-container">
                    <div className="about-left-box">
                        <h1 className="details-title">{AboutPage.contantTitle}</h1>
                        <p className="details-para">{AboutPage.contantPara1}</p>
                        <p className="details-para">{AboutPage.contantPara2}</p>
                        <div className="owner-details-box">
                            <img src={AboutPage.owner.image} alt="drImg" className='drImg' />
                            <div className="dr-details-box">
                                <p className="dr-name">{AboutPage.owner.name}</p>
                                <p className="dr-designation">{AboutPage.owner.designation}</p>
                            </div>
                        </div>
                    </div>
                    <div className="about-right-box">
                        <div className="time-table-box">
                            <h3 className="time-table-title">Weekly Timetable</h3>

                            {
                                AboutPage.Timetable.map((item, index) => (
                                    <div className="table-box" key={index}>
                                        <p className="time-title">{item.day}</p>
                                        <p className="time-title">{item.time}</p>
                                    </div>
                                ))
                            }


                            <div className="calling-box">
                                <FiPhoneCall className='calling-icon' />
                                <div className="call-details">
                                    <p className="call-title">Call Now</p>
                                    <p className="call-number">{AboutPage.contactNumber}</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About